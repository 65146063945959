// 1. Your custom variables and variable overwrites.
$global-font-size: 18px;
$global-font-family: mrs-eaves, serif;
$global-primary-background: #b40000;
$global-muted-background: hsl(0, 0%, 80%);

// 2. Import default variables and available mixins.
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-text-meta(){
    font-family: mrs-eaves-roman-all-small-ca, sans-serif;
}

@mixin hook-button(){
    font-family: mrs-eaves-roman-small-caps, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

@mixin hook-card-badge(){
    font-family: mrs-eaves-roman-small-caps, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

@mixin hook-card-title(){
    font-family: mrs-eaves-roman-small-caps, sans-serif;
    font-weight: 700;
}

// 4. Import UIkit
@import "~uikit/src/scss/uikit-theme.scss";

html {
    background-color: $global-secondary-background;
}